<template>
    <ion-header class="ion-no-border border-b border-gray-50">
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button @click="closeModal()">
                    <ion-img :src="require('@/assets/Icons/arrow-back-grey.svg')" class="w-6 h-6"></ion-img>
                </ion-button>
            </ion-buttons>
            <ion-title><ion-img :src="require('@/assets/images/SustainAbility-logo.svg')" class="w-20 mx-auto"></ion-img></ion-title>
        </ion-toolbar>
    </ion-header>
    <ion-content>
        <div class="p-10 flex flex-col flex-wrap h-full">
            <h2 class="mb-2 font-bold" v-if="content.content">Product Description</h2>
            <div class="mb-10" v-html="content.content" v-if="content.content"></div>
            <h2 class="mb-2 font-bold">Why Swap?</h2>
            <div class="mb-10">
                <ul class="flex flex-wrap gap-2 text-xs sm:text-sm">
                    <li
                        class="px-2 py-1 bg-gray-100 rounded-full opacity-95 text-gray-500"
                        v-for="con in content.cons"
                        :key="con"
                    >
                        <svg viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" class="inline fill-current text-gray-500 w-4 h-4">
                            <g fill="none" fill-rule="evenodd">
                                <path d="M0 0h42v42H0z"/>
                                <g transform="translate(3 3)" fill="currentColor" fill-rule="nonzero">
                                    <circle cx="11.769" cy="15.923" r="2.077"/>
                                    <path d="M18 20.77c3.93 0 7.236 2.555 8.283 6.042a.692.692 0 01-.681.88H10.398a.692.692 0 01-.677-.88c1.037-3.487 4.344-6.043 8.279-6.043z"/><circle cx="24.231" cy="15.923" r="2.077"/>
                                    <path d="M18-1.5C7.23-1.5-1.5 7.23-1.5 18S7.23 37.5 18 37.5 37.5 28.77 37.5 18 28.77-1.5 18-1.5zm0 3c9.113 0 16.5 7.387 16.5 16.5S27.113 34.5 18 34.5 1.5 27.113 1.5 18 8.887 1.5 18 1.5z"/>
                                </g>
                            </g>
                        </svg>
                        {{ con.name }}
                    </li>
                </ul>
            </div>
            <div v-if="swaps !== null">
                <h2 class="mb-2 flex font-bold items-center"><span class="text-white rounded-full flex items-center justify-center bg-blue-500 inline-block w-4 h-4 text-xs p-3 mr-2 font-light font-bold">{{ swaps.length }}</span> Available
                    {{ maybePluralize(swaps.length, 'Swap') }}</h2>
                <p>There {{ maybePluralize2(swaps.length, 'is', 'are') }} {{ swaps.length }} alternative products you could swap to that will save you money and help the environment!</p>
            </div>
            <div class="flex flex-wrap justify-center mt-auto button-row items-center absolute bottom-0 py-4 px-10 left-0 right-0 bg-white shadow-t">
                <ion-button icon-only fill="clear" class="w-10 h-10 text-gray-400 self-center" @click="closeModal()">
                    <ion-icon slot="icon-only" :icon="closeCircle" size="large"></ion-icon>
                </ion-button>
            </div>
        </div>
    </ion-content>
</template>

<script>
import {IonContent, IonHeader, IonTitle, IonIcon, IonImg, IonToolbar, IonButtons, IonButton, modalController} from '@ionic/vue';
import {image, closeCircle} from 'ionicons/icons';
import {defineComponent} from 'vue';

export default defineComponent({
    components: {IonContent, IonHeader, IonTitle, IonIcon, IonImg, IonToolbar, IonButtons, IonButton},
    name: 'Modal',
    props: {
        title: {type: String, default: 'Super Modal'},
        id: {type: Object}
    },
    setup() {
        const closeModal = () => {
            modalController.dismiss();
        };

        return {closeModal};
    },
    data() {
        const maybePluralize = (count, noun, suffix = 's') =>
            `${noun}${count !== 1 ? suffix : ''}`;
        const maybePluralize2 = (count, noun, suffix = 's') =>
            `${count !== 1 ? suffix : noun}`;
        console.log(this.$props.id);
        return {
            maybePluralize,
            maybePluralize2,
            image,
            closeCircle,
            content: this.$props.id,
            swaps: this.$props.id.products
        }
    },
});

</script>
<style>
ion-button {
    padding: 0;
    --padding-start: 0;
    --padding-end: 0;
}
ion-icon {
    --ionicon-stroke-width: 16px;
}
</style>
