<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="$router.go(-1)">
                        <ion-img :src="require('@/assets/Icons/arrow-back-grey.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
                <ion-title><h1 class="text-base text-gray-400 text-center font-semibold">{{ room }}</h1>
                </ion-title>
                <ion-buttons slot="end">
                    <ion-button router-link="/home/settings">
                        <ion-img :src="require('@/assets/Icons/swap-reject-circle-solid-grey.svg')"
                                 class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
            <div class="shadow-none mx-auto my-auto h-8.5 flex justify-center rounded-none items-center border-b border-gray-200">
                <div class="flex items-center mr-3.6 text-gray-500">
                    <div
                        class="w-6.5 h-6.5 rounded-full bg-smcc-smcc-green flex justify-center items-center text-xxsm mr-1 text-white font-bold">
                        {{ acceptedCount }}
                    </div>
                    <p class="text-sm">Swaps</p>
                </div>
                <div class="flex items-center ml-3.6 text-gray-500">
                    <div
                        class="w-6.5 h-6.5 rounded-full bg-smcc-red flex justify-center items-center text-xxsm mr-1 text-white font-bold">
                        {{ declinedCount }}
                    </div>
                    <p class="text-sm">Rejected</p>
                </div>
            </div>
        </ion-header>
        <ion-content>
            <div class="pb-6.5 border-b border-smcc-grey-light-3 h-48"
                 v-for="swap in swaps"
                 :key="swap"
                 :id="swap.id"
            >
                <swiper class="mt-6.5" :slidesPerView="'auto'" :centeredSlides="true" :spaceBetween="15">
                    <swiper-slide class="flex justify-center items-center">
                        <ion-card class="w-full h-44 m-0 border border-smcc-grey-light-4 rounded-xl">
                            <div
                                class="w-full h-6.5 bg-smcc-smcc-grey text-white text-xsm flex justify-center items-center">
                                Swapped From
                            </div>
                            <div class="flex items-center">
                                <div class="w-1/3 relative">
                                    <div class="bg-gradient-to-l from-white absolute w-full h-full -right-1"></div>
                                    <ion-img :src="swap.image"
                                             class="object-cover w-26 h-26" alt="main image" height="106"
                                             width="106"></ion-img>
                                </div>
                                <div class="w-2/3">
                                    <h2 class="text-base text-smcc-smcc-grey font-bold w-6/12 mx-3.6 text-left leading-tight">{{ swap.title }}</h2>
                                </div>
                            </div>
                            <div class="w-full h-13 bg-smcc-grey-light-2 flex items-center">
                                <ion-button fill="clear" @click="openSwapModal(swap)" class="px-4">
                                    <ion-img :src="require('@/assets/Icons/swap-info-circle-solid-grey.svg')" class="w-7.5 h-7.5"></ion-img>
                                </ion-button>
                                <ion-button icon-only fill="clear" class="text-gray-500 rounded-full px-4" @click="goToSwaps(swap.room_id, swap.id)">
                                    <ion-img :src="require('@/assets/Icons/swap-gridarrows.svg')" class="w-7.5 h-7.5"></ion-img>
                                </ion-button>
                            </div>
                        </ion-card>
                    </swiper-slide>

                    <swiper-slide class="flex justify-center items-center"
                        v-for="product in swap.fullProducts"
                        :key="product"
                        :id="product.id"
                    >
                        <ion-card
                            class="w-full h-44 m-0 border rounded-xl"
                            :class="[ product.accepted === true ? 'border-smcc-smcc-green' : product.declined === true ? 'border-smcc-red' : '' ]"
                        >
                            <div
                                class="w-full h-6.5 text-white text-xsm flex justify-center items-center"
                                :class="[ product.accepted === true ? 'bg-smcc-smcc-green' : product.declined === true ? 'bg-smcc-red' : 'bg-gray-300' ]"
                                v-html="[ product.accepted === true ? 'Swap Picked' : product.declined === true ? 'Swap Rejected' : '' ]"
                            >
                            </div>
                            <div class="flex items-center">
                                <div class="w-1/3 relative">
                                    <div class="bg-gradient-to-l from-white absolute w-full h-full -right-1"></div>
                                    <ion-img :src="'https://www.savemoneycutcarbon.com/wp-content/uploads'+product.product_image_main" class="object-cover w-26 h-26" alt="main image" ></ion-img>
                                </div>
                                <div class="w-2/3">
                                <h2 class="text-base text-smcc-smcc-grey font-bold w-6/12 mx-3.6 line-clamp-3 leading-tight">{{ product.name.split("|")[0] }}</h2>
                                </div>
                            </div>
                            <div class="w-full h-13 bg-smcc-grey-light-2 flex flex-wrap items-center justify-items-start">
                                <ion-button fill="clear" @click="openModal(product, 'info')" class="self-start px-4">
                                    <ion-img :src="require('@/assets/Icons/swap-info-circle-solid-grey.svg')"
                                             ></ion-img>
                                </ion-button>
                                <ion-button fill="clear" @click="openModal(product, 'gallery')" class="self-start px-4">
                                    <ion-img :src="require('@/assets/Icons/swap-photos-solid-grey.svg')"
                                             ></ion-img>
                                </ion-button>
                                <ion-button icon-only fill="clear" @click="doDecline(product)" v-if="product.accepted === true" class="justify-self-end ml-auto text-gray-500 rounded-full px-4">
                                        <ion-img :src="require('@/assets/Icons/swap-reject-circle-solid-grey.svg')"></ion-img>
                                </ion-button>
                                <ion-button icon-only fill="clear" @click="doSwap(product)" v-if="product.accepted === false" class="justify-self-end ml-auto text-gray-500 rounded-full px-4">
                                    <ion-img :src="require('@/assets/Icons/icons-app-swap-accep-circle-solid.svg')"></ion-img>
                                </ion-button>
                            </div>
                        </ion-card>
                    </swiper-slide>
                </swiper>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonContent,
    IonButton,
    IonCard,
    IonButtons,
    modalController,
    IonicSwiper,
    IonImg,
    alertController
} from '@ionic/vue';
import { inject } from "vue";
import {useRouter} from 'vue-router';
import SwiperCore, {Navigation, Pagination} from 'swiper';
import { get, set } from '@/store/storage';
import Modal from '@/components/Modal.vue'
import SwapModal from '@/components/SwapModal.vue'
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';
import {information} from 'ionicons/icons';


SwiperCore.use([IonicSwiper, Navigation, Pagination]);

export default {
    components: {
        IonPage,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonContent,
        IonButton,
        IonCard,
        IonButtons,
        Swiper,
        SwiperSlide,
        IonImg
    },
    data() {
        const emitter = inject("emitter");   // Inject `emitter`
        return {
            declined_products: [],
            accepted_products: [],
            declined_swaps: [],
            user: [],
            swaps: [],
            room: [],
            declinedCount: 0,
            acceptedCount: 0,
            roomId: this.$route.params.id,
            emitter
        }
    },
    setup() {
        const openModal = async (id, type) => {
            const modal = await modalController.create({
                component: Modal, //Modal is name of the component to render inside ionic modal
                componentProps: {
                    id: id,
                    type: type
                },
                swipeToClose: true,
                backdropDismiss: true,
            });
            return modal.present();
        };
        const openSwapModal = async (id) => {
            const modal = await modalController.create({
                component: SwapModal, //Modal is name of the component to render inside ionic modal
                componentProps: {
                    id: id
                },
                swipeToClose: true,
                backdropDismiss: true
            });
            return modal.present();
        };
        const router = useRouter();
        return {
            router,
            openModal,
            openSwapModal,
            information
        };
    },
    methods: {
        async doSwap(object) {
            const alert = await alertController
                .create({
                    cssClass: 'basic-alert',
                    header: 'Add to My Swaps list:',
                    message: '<strong>' + object.name + '</strong>' + '<br><br>Are you sure?',
                    buttons: [
                        {
                            text: 'Yes',
                            handler: () => {
                                console.log(object)
                                const room = this.roomId.toString();
                                const swap = object.swap_id[0].toString();
                                const product = object.id.toString();
                                this.removeDecline({room, swap, product})
                                /**
                                 * Append the current product to an array
                                 */
                                this.accepted_products.push({room, swap, product});
                                /**
                                 * Set local storage of the current product
                                 */
                                set('accepted_products', this.accepted_products).then(() => {
                                    this.reloadView()
                                });

                            },
                        },
                        {
                            text: 'No',
                            role: 'cancel',
                            handler: (blah) => {
                                console.log('Confirm Cancel:', blah)
                            },
                        },
                    ],
                });
            return alert.present();
        },
        async doDecline(object) {
            const alert = await alertController
                .create({
                    cssClass: 'basic-alert',
                    header: 'Reject Swap:',
                    message: '<strong>' + object.name + '</strong>' + '<br><br>Are you sure?',
                    buttons: [
                        {
                            text: 'Yes',
                            handler: () => {
                                console.log(object)
                                const room = this.roomId.toString();
                                const swap = object.swap_id[0].toString();
                                const product = object.id.toString();
                                this.removeSwap({room, swap, product})
                                /**
                                 * Append the current product to an array
                                 */
                                this.declined_products.push({room, swap, product});
                                /**
                                 * Set local storage of the current product
                                 */
                                set('declined_products', this.declined_products).then(() => {
                                    this.reloadView()
                                });
                            },
                        },
                        {
                            text: 'No',
                            role: 'cancel',
                            handler: (blah) => {
                                console.log('Confirm Cancel:', blah)
                            },
                        },
                    ],
                });
            return alert.present();

        },
        removeSwap(remove) {
            get('accepted_products').then((r) => {
                if(r !== null) {
                    let index = r.findIndex(
                        i => i.room === remove.room &&
                            i.swap === remove.swap &&
                            i.product === remove.product
                    );

                    // TODO: Add confirmation box

                    if (index !== -1) {
                        r.splice(index, 1)
                    }
                    this.accepted_products = r
                    set('accepted_products', r);
                }
            });
        },
        removeDecline(remove) {
            get('declined_products').then((r) => {
                console.log(r);
                if(r !== null) {
                    let index = r.findIndex(
                        i => i.room === remove.room &&
                            i.swap === remove.swap &&
                            i.product === remove.product
                    );

                    // TODO: Add confirmation box
                    if (index !== -1) {
                        r.splice(index, 1)
                    }
                    console.log(r);
                    this.declined_products = r
                    set('declined_products', r);
                }
            });
        },
        goToSwaps(room, swap) {
            this.router.push({path: '/home/rooms/' + room + '/swaps/' + swap + '/products', append: true})
        },
        async reloadView() {
            const rooms = await get('rooms_data');
            this.room = rooms.data.filter(element => element.id === parseInt(this.$route.params.id))[0].title
            this.swaps = await this.$store.getters.swapHistory(this.roomId);
            this.declinedCount = await this.$store.getters.declinedCount(this.roomId) //(this.declined !== null) ? this.declined.length : 0;
            this.acceptedCount = await this.$store.getters.acceptedCount(this.roomId) //(this.accepted !== null) ? this.accepted.length : 0;
        }
    },
    async ionViewWillEnter() {
        this.roomId = this.$route.params.id;
        const rooms = await get('rooms_data');
        this.declined = await get('declined_products');
        this.accepted = await get('accepted_products');
        this.room = rooms.data.filter(element => element.id === parseInt(this.$route.params.id))[0].title
        this.swaps = await this.$store.getters.swapHistory(this.roomId);
        this.declinedCount = await this.$store.getters.declinedCount(this.roomId) //(this.declined !== null) ? this.declined.length : 0;
        this.acceptedCount = await this.$store.getters.acceptedCount(this.roomId) //(this.accepted !== null) ? this.accepted.length : 0;
    },

}
</script>

<style>

.swiper-container {
    overflow: initial;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    width: 80%;
}
.basic-alert {
    --backdrop-opacity: 0.6;
    --background: black;
}
.alert-title.sc-ion-alert-md, .alert-title.sc-ion-alert-ios {
    @apply text-white text-left text-sm font-normal
}
.alert-message.sc-ion-alert-md, .alert-message.sc-ion-alert-ios {
    @apply text-white text-left
}
.alert-button-group.sc-ion-alert-ios, .alert-button-group.sc-ion-alert-md {
    @apply p-4 gap-2 justify-items-center
}
.alert-button.sc-ion-alert-md, .alert-button.sc-ion-alert-ios {
    border:inherit;
    min-width: auto;
    @apply text-white bg-black border border-b-2 border-t-2 border-l-2 border-r-2 border-white rounded-sm;
}
.alert-button.sc-ion-alert-md:last-child, .alert-button.sc-ion-alert-ios:last-child {
    border-right: inherit;
    @apply border-r-2 border-white font-normal
}

</style>
